<template>
    <div>
        <div id="vvt-list-lft-header">
            <v-subheader 
                v-if="categories && categories.length"
                class="primary--text pb-3 px-0 pt-3">
                <span
                    :class="{
                        'text-body-2': $vuetify.breakpoint.lgAndDown,
                        'text-subtitle-1': $vuetify.breakpoint.xl,
                    }"
                    class="font-weight-bold text-truncate"
                    >{{ $t('domains') }}</span
                >
            </v-subheader>
            <template v-if="$wait.is('fetch categories')">
                <v-skeleton-loader
                    v-for="n in 3"
                    :key="n"
                    loading
                    :type="'card-heading'"
                    tile
                    class="px-0 mx-0 my-0 mt-5 block"
                    width="100%"
                ></v-skeleton-loader>
            </template>
            <template v-if="!$wait.is('fetch categories')">
                <v-alert
                    v-if="!allCategories || !allCategories.length"
                    text
                    color="info"
                    icon="mdi-information-outline"
                >
                    <h3 class="h6" v-t="'domains'" />
                    <div class="subtitle-2 pt-3" v-t="'vvtList.templates.missingDomainsAlert.createDomainText'" />
                    <v-btn
                        :to="{ name: 'SettingsDomains' }"
                        color="info"
                        class="mt-3"
                        outlined
                        v-t="'vvtList.templates.missingDomainsAlert.createDomainButton'"
                    />
                    <v-divider
                        class="my-4 info"
                        style="opacity: 0.22"
                    ></v-divider>
                    <div class="subtitle-2" v-t="'vvtList.templates.missingDomainsAlert.groupSettingsText'" />
                    <v-btn
                        :to="{ name: 'SettingsCompanyGroup' }"
                        color="info"
                        class="mt-3"
                        outlined
                        v-t="'vvtList.templates.missingDomainsAlert.groupSettingsButton'"
                    />
                    <v-divider
                        class="my-4 info"
                        style="opacity: 0.22"
                    ></v-divider>
                </v-alert>
                <v-alert
                    v-else-if="!categories || !categories.length"
                    text
                    color="info"
                    icon="mdi-information-outline"
                >
                    <h3 class="h6">{{ $tc('companyGroupTemplates',2) }}</h3>
                    <div class="subtitle-2 pt-3" v-t="'vvtList.templates.create.createFirstTemplateText'" />
                    <v-btn
                        :to="{ name: 'SettingsVvtGroupTemplatesSelectFromTemplate' }"
                        color="success"
                        class="mt-3"
                        outlined
                        v-t="'vvtList.templates.create.label'"
                    />
                    <v-divider
                        class="my-4 info"
                        style="opacity: 0.22"
                    ></v-divider>
                    <div class="subtitle-2" v-t="'vvtList.templates.missingDomainsAlert.groupSettingsText'" />
                    <v-btn
                        :to="{ name: 'SettingsCompanyGroup' }"
                        color="info"
                        class="mt-3"
                        outlined
                        v-t="'vvtList.templates.missingDomainsAlert.groupSettingsButton'"
                    />
                    <v-divider
                        class="my-4 info"
                        style="opacity: 0.22"
                    ></v-divider>
                </v-alert>
            </template>
        </div>
        <div :style="listStyle">
            <template v-if="!$wait.is('fetch categories') && categories && categories.length">
                <v-list
                    class="list--multiline list--link"
                    subheader
                    tile
                    dense
                >
                    <v-list-item
                        @click="$emit('select-category', {id: 'all'})"
                        href
                        tag="a"
                        :class="categoryClassObject('all')"
                        color="primary"
                        :ripple="false"
                        class="mb-3"
                    >
                        <v-list-item-content>
                            {{ $t('all') }}
                        </v-list-item-content>
                    </v-list-item>
                    <template>
                        <v-list-item
                            v-for="category in categories"
                            :key="category.id"
                            @click="$emit('select-category', category)"
                            href
                            tag="a"
                            :class="categoryClassObject(category.id)"
                            color="primary"
                            :ripple="false"
                            class="mb-3"
                        >
                            <template v-slot:default>
                                <v-list-item-content>
                                {{ category.title }}
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </template>
                </v-list>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'VvtGroupTemplateCategories',
    props: {
        selectedCategory: Object,
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/List/en.json'),
            de: require('@/locales/vvt/List/de.json'),
        },
    },
    mounted () {
        this.initMount();
    },
    data () {
        return {
            allCategories: [],
            listMaxHeight: '100%',
            listStyle: null
        }
    },
    computed: {
        ...mapGetters({
            categories: 'domain/getItems'
        }),
    },
    methods: {
        ...mapActions({
            fetchDomains: 'domain/fetch'
        }),
        setListStyle() {
            const styleObj = {};
            this.listStyle = styleObj;
            let el = document.getElementById('vvt-list-lft-header');
            let leftSideBarHeaderHeight = 0;
            if(el) {
                leftSideBarHeaderHeight = this.$outerHeight(el);
            }
            if (this.listMaxHeight) {
                if (this.listMaxHeight === '100%') {
                    styleObj.height = `calc(100vh - ${this.$vuetify.application.top}px - ${this.$footerHeight()}px - ${leftSideBarHeaderHeight}px - 14px)`;
                } else {
                    styleObj.height = this.listMaxHeight + 'px';
                }
                styleObj.overflow = 'auto';
            }
            this.listStyle = styleObj;
            return styleObj;
        },
        async initMount () {
            await this.fetchCategories();
            await this.fetchAllCategories();
            this.$nextTick(() => {
                this.setListStyle();
            })
        },
        fetchAllCategories () {
            return new Promise(resolve => {
                this.$wait.start('fetch categories');
                this.fetchDomains({disableStoreUpdate: true}).then(result => {
                    this.$wait.end('fetch categories');
                    this.allCategories = result.data;
                    resolve(result.data);
                });
            });
        },
        fetchCategories () {
            return new Promise(resolve => {
                this.$wait.start('fetch categories');
                this.fetchDomains({countTemplates: true}).then(result => {
                    this.$wait.end('fetch categories');
                    resolve(result.data);
                });
            });
        },
        isActive(categoryId) {
            return (
                this.selectedCategory && this.selectedCategory.id === categoryId
            );
        },
        categoryClassObject(categoryId) {
            return {
                'v-list-item--active': this.isActive(categoryId),
            };
        },
        getCategoryInformation(category) {
            return category.city
                ? category.city + ', ' + category.countryName
                : category.countryName;
        },
    },
};
</script>
